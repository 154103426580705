import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Switch,
    Route,
    Link,
    BrowserRouter,
    useLocation
} from 'react-router-dom';

import styles from '../Styles/Home.module.css';

import Header from './Header';

const Home = () => {
    const [notes, setNotes] = React.useState([
        'Заметка 1',
        'Заметка 2',
        'Заметка 3',
        'Заметка 4',
        'Заметка 5'
    ]);
    
    const buttonRef = React.useRef(null); // для фокуса на кнопке

    React.useEffect(() => {
        // Прокручиваем страницу, чтобы кнопка была по центру
        if (buttonRef.current) {
          const buttonPosition = buttonRef.current.getBoundingClientRect().top;
          const scrollAmount = window.innerHeight / 2 - buttonPosition;
          window.scrollTo({
            top: scrollAmount,
            behavior: 'smooth'
          });
        }
      }, []);

    return (
        <>
            <Header />
            <div className={styles.container}>
                <div className={styles.notes}>
                    {notes.map((note, index) => (
                    <div key={index} className={styles.note}>
                        {note}
                    </div>
                    ))}
                </div>
                <div className={styles.newNote}>
                    <Link to='/new' className={styles.newNoteButton} ref={buttonRef}>+ Добавить новую заметку</Link>
                </div>
                <div className={styles.collections}>Тут могут быть ваши коллекции</div>
            </div>
        </>
    )
}

export default Home;