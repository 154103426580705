import React from 'react';

import styles from '../Styles/Header.module.css';

const Header = () => {
    // React.useEffect(() => {
    //     window.Telegram.WebApp.ready();
    // }, []);
    // const userPhoto = window.Telegram.WebApp.initDataUnsafe.user.photo_url;

    return (
        <div className={styles.header}>
            <div>
                <img className={styles.logoImg} src='/img/logo/holo2-t.png' alt='Logo' />
            </div>
            {/* {window.Telegram.WebApp.initDataUnsafe?.user?.username} */}
            <div className={styles.profile}>
            <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" id="user-circle"><path d="M12,2A10,10,0,0,0,4.65,18.76h0a10,10,0,0,0,14.7,0h0A10,10,0,0,0,12,2Zm0,18a8,8,0,0,1-5.55-2.25,6,6,0,0,1,11.1,0A8,8,0,0,1,12,20ZM10,10a2,2,0,1,1,2,2A2,2,0,0,1,10,10Zm8.91,6A8,8,0,0,0,15,12.62a4,4,0,1,0-6,0A8,8,0,0,0,5.09,16,7.92,7.92,0,0,1,4,12a8,8,0,0,1,16,0A7.92,7.92,0,0,1,18.91,16Z"></path></svg>
            </div>
        </div>
    )
}

export default Header;